import '../App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Banner } from "../components/Banner";
import { Projects } from "../components/Projects";
import { Footer } from "../components/Footer";

export default function Home() {
  return (
    <div>
      <Banner />
      <Projects />
      <Footer />
    </div>
  );
}