import './App.css';
import Home from './pages/Home';
import Blog from './pages/Blog';
import { NavBar } from "./components/NavBar";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Routes, Route } from 'react-router-dom';

function App() {
  return (
    <div>
      <NavBar />
      <Routes>
          <Route path='/' element={<Home/>} />
          <Route path='/blog' element={<Blog/>} />
      </Routes>
    </div>
  );
}

export default App;