import 'animate.css';
import { Container, Row, Col, Card, ListGroup } from "react-bootstrap";

const articles = [
    {
        id: "1",
        link: "",
        title: "TypeScript - How to create a Game Engine",
        image: "",
        content: 'When I considered developing a "Game Engine," the first thing I kept asking myself was, "How do I start?" Well, after several unsuccessful attempts, I discovered that you need to start small.',
        author: "Mark Ringtved Nielsen",
        tags: ['TypeScript', 'Game Programming'],
    }
];

export const Banner = () => {
    return (
        <section className='blog__banner'>
            <Container>
                <Row className="aligh-items-center">
                    {articles.map(({ title, content, tags }, index) => {
                        return (
                            <Col xs={12} md={6} lg={6} xl={4} key={index}>
                                <article>
                                    <Card>
                                        <Card.Body className='p-5'>
                                            <Card.Title><h1 className='h4'>{title}</h1></Card.Title>
                                            <Card.Text className='pt-2 fw-light body'>{content}</Card.Text>
                                            <ListGroup horizontal>
                                                {tags.map((tag, index) => <ListGroup.Item key={index}>{tag}</ListGroup.Item>)}
                                            </ListGroup>
                                        </Card.Body>
                                    </Card>
                                </article>
                            </Col>
                        )
                    })}
                </Row>
            </Container>
        </section>
    )
}
