import '../App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Banner } from '../components/BlogPage/Banner';

export default function Blog() {
  return (
    <div>
      <Banner />
    </div>
  );
}